define('ember-initials/-private/generators', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.revokeImage = revokeImage;
  exports.generateImage = generateImage;
  exports.generateInitials = generateInitials;

  function _generateElement(name) {
    var styles = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
    var attrs = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    return _ember['default'].$(name).attr(attrs).css(styles);
  }

  function generateTextElement(text, color) {
    var styles = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    return _generateElement('<text></text>', styles, {
      y: '50%',
      x: '50%',
      dy: '0.35em',
      'text-anchor': 'middle',
      'pointer-events': 'auto',
      fill: color
    }).html(text);
  }

  function generateSvgElement(width, height) {
    var styles = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    return _generateElement('<svg></svg>', styles, {
      width: width,
      height: height,
      xmlns: 'http://www.w3.org/2000/svg',
      'pointer-events': 'none',
      'viewBox': '0 0 100 100'
    });
  }

  function capitalizedFirstLetter(word) {
    return word ? word[0].toUpperCase() : '';
  }

  function revokeImage(url) {
    URL.revokeObjectURL(url);
  }

  function generateImage(properties) {
    var textElement = generateTextElement(properties.initials, properties.initialsColor, properties.textStyles);
    var svgElement = generateSvgElement(properties.width, properties.height, properties.backgroundStyles);

    svgElement.append(textElement);
    var finalElement = _ember['default'].$('<div>').append(svgElement);
    var blob = new Blob([finalElement.html()], { type: "image/svg+xml" });
    return URL.createObjectURL(blob);
  }

  function generateInitials(name) {
    var initials = name ? name.trim() : '';
    var letters = initials.split(' ');

    if (letters.length > 1) {
      var first = capitalizedFirstLetter(letters.shift());
      var last = capitalizedFirstLetter(letters.pop());
      initials = first + last;
    } else if (letters.length === 1) {
      initials = capitalizedFirstLetter(letters.shift());
    }

    return initials;
  }
});