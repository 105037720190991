define('ember-intl/helpers/format-date', ['exports', 'ember-intl/helpers/-format-base'], function (exports, _emberIntlHelpersFormatBase) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  exports['default'] = _emberIntlHelpersFormatBase['default'].extend({
    allowEmpty: true,

    format: function format(value, options) {
      return this.intl.formatDate(value, options);
    }
  });
});