define('ember-notify/message', ['exports'], function (exports) {
  'use strict';

  var EMPTY_ARRAY = [];

  exports['default'] = Ember.Object.extend({
    text: null,
    html: '',
    type: 'info',
    closeAfter: undefined,
    visible: undefined,
    classNames: EMPTY_ARRAY
  });
});