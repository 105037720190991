define('ember-intl/helpers/format-relative', ['exports', 'ember', 'ember-intl/helpers/-format-base'], function (exports, _ember, _emberIntlHelpersFormatBase) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var runBind = _ember['default'].run.bind;

  exports['default'] = _emberIntlHelpersFormatBase['default'].extend({
    format: function format(params, hash) {
      return this.intl.formatRelative(params, hash);
    },

    compute: function compute(params, hash) {
      this.clearTimer();

      if (hash && typeof hash.interval !== 'undefined') {
        /* setTimeout versus Ember.run.later so tests will not wait infinitely */
        this.timer = setTimeout(runBind(this, this.recompute), parseInt(hash.interval, 10));
      }

      return this._super(params, hash);
    },

    clearTimer: function clearTimer() {
      clearTimeout(this.timer);
    },

    willDestroy: function willDestroy() {
      this._super();

      this.clearTimer();
    }
  });
});