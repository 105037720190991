/**
* @desc mRadarChart is a standalone JS plugin that allows you to create radar
* charts from label and data arrays.
* @author Murtada al Mousawy (http://murtada.nl)
* @license MIT
*/

;(function(window) {

  'use strict';

  var document = window.document;

  function mRadarChart(selector, options) {
    this._init(selector, options);

    return {
      createChart: this.createChart.bind(this),
      destroyChart: this.destroyChart.bind(this),
      getData: this.getData.bind(this),
      setData: this.setData.bind(this),
      getLabels: this.getLabels.bind(this),
      setLabels: this.setLabels.bind(this),
			svgElement: this.svgElement
    };
  }

  mRadarChart.prototype = {
    _init: function(selector, options) {
      this.selected_elements = document.querySelectorAll(selector);
      this.selector = selector;
      this.options = options;
      this.options.labelOffset = ((typeof this.options.labelOffset == 'undefined') ? 30 : this.options.labelOffset);
      this.line_angles = [];
      this.line_ends = [];
      this.line_starts = [];
      this.chart_data_pos = [];
      this.mouseup_eventlistener = null;
      this.options.pointSize = ((typeof this.options.pointSize == 'undefined') ? 3 : this.options.pointSize);
      this.options.step = ((typeof this.options.step == 'undefined') ? false : this.options.step);
      this.options.lineStep = ((typeof this.options.lineStep == 'undefined') ? 1 : this.options.lineStep);
      this.options.innerPadding = ((typeof this.options.innerPadding == 'undefined') ? 30 : this.options.innerPadding);
      this.options.outerPadding = ((typeof this.options.outerPadding == 'undefined') ? 60 : this.options.outerPadding);
			this.options.outerPadding += this.options.innerPadding;

      this._createChart();
    },

    _createChart: function() {
      for (var selected_elements_index = 0; selected_elements_index < this.selected_elements.length; selected_elements_index++) {
        var current_svg = this.selected_elements[selected_elements_index];

        if (current_svg instanceof SVGElement) {
          //
          if (this.options.readOnly) {
            current_svg.classList.add('is-read-only');
          }
          var chart_bbox = current_svg.getBBox();
          var chart_size = current_svg.getBoundingClientRect();
          this.chart_size = chart_size;
          var chart_center = {x: chart_size.width * .5, y: chart_size.height * .5};
          this.chart_center = chart_center;
          var radius = Math.min(chart_center.x, chart_center.y);
          var transform_origin = chart_center.x + 'px ' + chart_center.y + 'px';
          this.options.radius = this.options.radius || radius;
					this.options.radius -= this.options.outerPadding;
          this.options.maxValue = this.options.maxValue || Math.max.apply(null, this.options.data);
          //
          var background_group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          background_group.classList.add('background-group');
          background_group.style.transformOrigin = transform_origin
          var chart_group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          chart_group.classList.add('chart-group');
          chart_group.style.transformOrigin = transform_origin
          var point_group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          point_group.classList.add('point-group');
          point_group.style.transformOrigin = transform_origin
          var foreground_group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          foreground_group.classList.add('foreground-group');

          this.chart_group = chart_group;

          current_svg.appendChild(background_group);
          current_svg.appendChild(chart_group);
          current_svg.appendChild(point_group);
          current_svg.appendChild(foreground_group);

          for (var label_index = 0, labels_length = this.options.labels.length; label_index < labels_length; label_index++) {
            var line_angle = (label_index/labels_length)*Math.PI*2 - 1.5708;
            this.line_angles.push(line_angle);

            var line_start = {x: chart_center.x + Math.cos(line_angle) * this.options.innerPadding, y: chart_center.y + Math.sin(line_angle) * this.options.innerPadding};
            var line_end = {x: chart_center.x + Math.cos(line_angle) * (this.options.radius + this.options.innerPadding), y: chart_center.y + Math.sin(line_angle) * (this.options.radius + this.options.innerPadding)};
						this.line_starts.push(line_start);
						this.line_ends.push(line_end);

            var label_pos = {x: line_start.x + Math.cos(line_angle) * (this.options.radius + this.options.labelOffset), y: line_start.y + Math.sin(line_angle) * (this.options.radius + this.options.labelOffset)};
            var data_pos = {x: line_start.x + Math.cos(line_angle) * (this.options.data[label_index]/this.options.maxValue) * this.options.radius, y: line_start.y + Math.sin(line_angle) * (this.options.data[label_index]/this.options.maxValue) * this.options.radius};
            this.chart_data_pos.push(data_pos);
            var label = this._createText(current_svg, label_pos, this.options.labels[label_index]);
            label.classList.add('label');
            background_group.appendChild(label);
            background_group.appendChild(this._createLine(this.chart_center, line_end));
            var circle = this._createCircle(data_pos, label_index);
            this._initCircleEvents(circle, current_svg);
            point_group.appendChild(circle);

						if (label_index > 0) {
							for (var value_index = 0; value_index <= this.options.maxValue; value_index+=this.options.lineStep) {
								var line_start_prev = this.line_starts[(label_index -1)];
								var prev_line_angle = this.line_angles[(label_index - 1)];

								var multiplier = (value_index/this.options.maxValue) * this.options.radius;
								var value_line_start = {x: line_start.x + Math.cos(line_angle) * multiplier, y: line_start.y + Math.sin(line_angle) * (value_index/this.options.maxValue) * this.options.radius};
								var value_line_end = {x: line_start_prev.x + Math.cos(prev_line_angle) * multiplier, y: line_start_prev.y + Math.sin(prev_line_angle) * multiplier};

								background_group.appendChild(this._createLine(value_line_start, value_line_end));

								if (label_index == this.options.labels.length - 1) {
									line_start_prev = this.line_starts[0];
									prev_line_angle = this.line_angles[0];

									var multiplier = (value_index/this.options.maxValue) * this.options.radius;
									var value_line_start = {x: line_start.x + Math.cos(line_angle) * multiplier, y: line_start.y + Math.sin(line_angle) * (value_index/this.options.maxValue) * this.options.radius};
									var value_line_end = {x: line_start_prev.x + Math.cos(prev_line_angle) * multiplier, y: line_start_prev.y + Math.sin(prev_line_angle) * multiplier};
									background_group.appendChild(this._createLine(value_line_start, value_line_end));

								}

							}
						}
          }




          this.overlay_label = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          this.overlay_label.classList.add('overlay-label');
          this.overlay_label.setAttribute('overflow', 'visible');
          this.overlay_label_rect = this._createRect({x: 0, y: 0}, 50, 50);
          this.overlay_label_poly = this._createPolygon('0,0 10,0 5,8');

          this.overlay_label_text = this._createText(current_svg, {x: 0, y: 0}, '');

          this.overlay_label.appendChild(this.overlay_label_rect);
          this.overlay_label.appendChild(this.overlay_label_poly);
          this.overlay_label.appendChild(this.overlay_label_text);

          foreground_group.appendChild(this.overlay_label);

          this.chart_path = this._createPath(this.chart_data_pos, true);

          chart_group.appendChild(this.chart_path);

        } else {
          // Throw error if element is not instance of SVGElement
          console.error('Selected element with the tag "' + (current_svg.tagName) + '" is not an SVGElement with selector "' + this.selector + '" at index ' + selected_elements_index, current_element);
        }

        this.mouseup_eventlistener = document.addEventListener('mouseup', this._handleMouseUp.bind(this, current_svg));
        this.mouseup_eventlistener = document.addEventListener('touchend', this._handleMouseUp.bind(this, current_svg));

        current_svg.addEventListener('mousemove', this._throttle(this._handleMouseMove.bind(this, current_svg), 16));

        current_svg.addEventListener('touchmove', this._throttle(this._handleMouseMove.bind(this, current_svg), 16));

				current_svg.change_event = new Event('change');
				current_svg.move_event = new Event('move');

				this.svgElement = current_svg;
      }
    },

    _destroyChart: function() {
      removeEventListener('mouseup', this.mouseup_eventlistener);
			this.line_angles = [];
      this.line_ends = [];
      this.chart_data_pos = [];
      this.mouseup_eventlistener = null;

      for (var selected_elements_index = 0; selected_elements_index < this.selected_elements.length; selected_elements_index++) {
        var current_svg = this.selected_elements[selected_elements_index];

        if (current_svg instanceof SVGElement) {
          while (current_svg.lastChild) {
            current_svg.removeChild(current_svg.lastChild);
          }
        }
      }
    },

    _createRect: function (start_pos, width, height) {
      var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('x', start_pos.x);
      rect.setAttribute('y', start_pos.y);
      rect.setAttribute('width', width);
      rect.setAttribute('height', height);
      return rect;
    },

    _createPolygon: function (points) {
      var polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
      polygon.setAttribute('points', points);
      return polygon;
    },

    _createText: function (current_svg, pos, text) {
      var label = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      label.textContent = text;

      current_svg.appendChild(label);
      var size = label.getBBox();
      current_svg.removeChild(label);

      label.setAttribute('x', pos.x - size.width * .35);
      label.setAttribute('y', pos.y + size.height * .25);
      return label;
    },

    _createLine: function (start_pos, end_pos) {
      var line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', start_pos.x);
      line.setAttribute('y1', start_pos.y);
      line.setAttribute('x2', end_pos.x);
      line.setAttribute('y2', end_pos.y);
      return line;
    },

    _createPath: function (data, create_element) {
      var path_data = 'M' + data[0].x + ' ' + data[0].y;

      for (var data_index = 1, data_length = data.length; data_index < data_length; data_index++) {
        var pos = data[data_index];
        path_data += ' L' + pos.x + ' ' + pos.y;
      }

      path_data += ' Z';

      if (create_element) {
        var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', path_data);
        return path;
      } else {
        return path_data;
      }
    },

    _redrawPath: function () {
      this.chart_path.setAttribute('d', this._createPath(this.chart_data_pos, false));
    },

    _createCircle: function (center_pos, index) {
      var circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      circle.setAttribute('data-index', index);
      circle.setAttribute('cx', center_pos.x);
      circle.setAttribute('cy', center_pos.y);
      circle.setAttribute('r', this.options.pointSize);
      return circle;
    },

    _showOverlayLabel: function(pos, label_text) {
      this.overlay_label.classList.add('is-visible');
      this.overlay_label_text.textContent = label_text;

      var text_size = this.overlay_label_text.getBBox();
      this.overlay_label_text.setAttribute('x', 8);
      this.overlay_label_text.setAttribute('y', 2 + text_size.height);
      this.overlay_label_rect.setAttribute('width', text_size.width + 16);
      this.overlay_label_rect.setAttribute('height', text_size.height + 10);

      this.overlay_label_poly.setAttribute('transform', 'translate(' + ((text_size.width + 6) * .5) + ', ' + (text_size.height + 10) + ')');

      var label_size = this.overlay_label.getBBox();

      this.overlay_label.setAttribute('x', Math.round(pos.x - label_size.width * .5));
      this.overlay_label.setAttribute('y', Math.round(pos.y - label_size.height));
    },

    _hideOverlayLabel: function() {
      this.overlay_label.classList.remove('is-visible');
    },

    _initCircleEvents: function(circle, svg) {
      if (circle) {
        circle.addEventListener('mousedown', this._handleMouseDown.bind(this));
        circle.addEventListener('touchstart', this._handleMouseDown.bind(this));
        circle.addEventListener('mouseenter', this._handleMouseEnter.bind(this));
        circle.addEventListener('mouseleave', this._handleMouseLeave.bind(this, svg));
      }
    },

    _getClosestPointOnLineSegment: function(point_a, point_b, point_p, step) {
      var d_point_ap = {
        dx: point_p.x - point_a.x,
        dy: point_p.y - point_a.y
      };

      var d_point_ab = {
        dx: point_b.x - point_a.x,
        dy: point_b.y - point_a.y
      };

      var d_ab = d_point_ab.dx * d_point_ab.dx + d_point_ab.dy * d_point_ab.dy;
      var prod_abap = d_point_ab.dx * d_point_ap.dx + d_point_ab.dy * d_point_ap.dy;
      var distance = (prod_abap / d_ab) || 0;

      if (step) {
        distance = Math.round(distance * (this.options.maxValue / step)) / (this.options.maxValue / step);
      }

      if (distance < 0) {
        distance = 0;
        return {pos: point_a, distance: distance};
      } else if (distance > 1) {
        distance = 1;
        return {pos: point_b, distance: distance};
      } else {
        return {pos: {x: point_a.x + d_point_ab.dx * distance, y: point_a.y + d_point_ab.dy * distance}, distance: distance};
      }
    },

    // Getters and Setters
    setData: function(data) {
      this.options.data = data;
      this._destroyChart();
      this._createChart();
    },

    setLabels: function(labels) {
      this.options.labels = labels;
      this._destroyChart();
      this._createChart();
    },

    getData: function() {
      return this.options.data;
    },

    getLabels: function() {
      return this.options.labels;
    },

    createChart: function() {
      this._createChart();
    },

    destroyChart: function() {
      this._destroyChart();
    },

    _throttle: function(fn, wait) {
      var time = Date.now(),
      scroll_timer = null;

      return function() {
        clearTimeout(scroll_timer);
        scroll_timer = setTimeout(function() { fn() }, wait);

        if ((time + wait - Date.now()) < 0) {
          fn();
          time = Date.now();
        }
      }
    },

    // Event Handlers
    _handleMouseUp: function(svg_element) {
      // MouseUp for entire chart
      this.dragging = false;
      var hovered_element = svg_element.querySelector('.is-hovered');
      if (hovered_element) {
        hovered_element.classList.remove('is-hovered');
      }

      var clicked_element = svg_element.querySelector('.is-clicked');

      if (clicked_element) {
        clicked_element.classList.remove('is-clicked');
				svg_element.dispatchEvent(svg_element.change_event);
        this._hideOverlayLabel();
      }
    },

    _handleMouseMove: function(svg_element) {
      var clicked_element = svg_element.querySelector('.is-clicked'),
      event = window.event;

			this.chart_size = svg_element.getBoundingClientRect();

      if (clicked_element && event) {
        var offsetX = 0;
        var offsetY = 0;

        if (event.type === 'touchmove') {
          offsetX = event.touches[0].pageX - (this.chart_size.left + window.scrollX);
          offsetY = event.touches[0].pageY - (this.chart_size.top + window.scrollY);
        } else {
          offsetX = event.pageX - (this.chart_size.left + window.scrollX);
          offsetY = event.pageY - (this.chart_size.top + window.scrollY);
        }

        var index = clicked_element.getAttribute('data-index');

        var closest_pos = this._getClosestPointOnLineSegment(this.line_starts[index], this.line_ends[index], {x: offsetX, y: offsetY}, this.options.step);

        this.chart_data_pos[index] = closest_pos.pos;


				if (this.options.step !== false && this.options.step % 1 == 0) {
          this.options.data[index] = Math.round(closest_pos.distance * this.options.maxValue);
          this._showOverlayLabel(closest_pos.pos, this.options.labels[index] + ': ' + this.options.data[index]);
        } else {
          this.options.data[index] = (closest_pos.distance * this.options.maxValue).toFixed(2);
          this._showOverlayLabel(closest_pos.pos, this.options.labels[index] + ': ' + this.options.data[index]);
        }

        this._redrawPath();

        clicked_element.setAttribute('cx', closest_pos.pos.x);
        clicked_element.setAttribute('cy', closest_pos.pos.y);

				svg_element.dispatchEvent(svg_element.move_event);
      }
    },

    _handleMouseDown: function(event) {
      if (!this.options.readOnly) {
        this.dragging = true;
        var element = event.target;

        element.classList.add('is-clicked');

        event.preventDefault();
      }
    },

    _handleMouseEnter: function(event, svg_element) {
      if (!this.dragging) {
        var element = event.target;
        var index = element.getAttribute('data-index');

        var circle_pos = this.chart_data_pos[index];

        element.classList.add('is-hovered');
        this._showOverlayLabel(circle_pos, this.options.labels[index] + ': ' + this.options.data[index]);
      }
    },

    _handleMouseLeave: function(svg_element) {
      if (!this.dragging) {
        var hovered_element = svg_element.querySelector('.is-hovered');
        if (hovered_element) {
          hovered_element.classList.remove('is-hovered');
        }
        this._hideOverlayLabel();
      }
    }
  }

  window.mRadarChart = mRadarChart;

}) (window);
