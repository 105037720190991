define('ember-trix/components/ember-trix', ['exports', 'ember', 'ember-trix/templates/components/ember-trix', 'lodash/lodash'], function (exports, _ember, _emberTrixTemplatesComponentsEmberTrix, _lodashLodash) {
  'use strict';

  var Trix = window.Trix;
  var computed = _ember['default'].computed;
  var isNone = _ember['default'].isNone;
  var isPresent = _ember['default'].isPresent;
  var set = _ember['default'].set;

  var TRIX_EVENTS = ['trix-attachment-add', 'trix-attachment-remove', 'trix-blur', 'trix-change', 'trix-file-accept', 'trix-focus', 'trix-initialize', 'trix-selection-change'];

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberTrixTemplatesComponentsEmberTrix['default'],
    classNames: ['trix-editor-wrapper'],

    init: function init() {
      this._super.apply(this, arguments);
      // merge config
      if (isPresent(this.attrs.config)) {
        Trix.config = _lodashLodash['default'].merge(Trix.config, this.get('config'));
      }
    },

    $trix: computed('_$trix', {
      get: function get() {
        var trix = undefined;
        if (isNone(this.get('_$trix'))) {
          trix = this.$('trix-editor');

          if (isPresent(trix)) {
            set(this, '_$trix', trix);
          }
        }
        return trix;
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      TRIX_EVENTS.forEach(function (eventName) {
        var eventHandler = _this.get(eventName);
        if (eventHandler) {
          _this.get('$trix').on(eventName, function (event) {
            return eventHandler(event.originalEvent);
          });
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      TRIX_EVENTS.forEach(function (eventName) {
        _this2.get('$trix').off(eventName);
      });
    },

    drop: function drop(event) {
      var data = event.dataTransfer.getData('text/html');
      var editor = event.target.editor;

      // look in parents for the trix editor
      if (!editor) {
        editor = $(event.target).parents('trix-editor')[0]['editor'];
      }

      var src = '';
      var findImage = function findImage(event, el) {
        if ($(el).is('img')) {
          src = el.src;
        }
      };

      $(data).each(findImage);

      if (src && editor) {
        var attributes = { url: src, contentType: 'image/png' };
        var attachment = new Trix.Attachment(attributes);
        editor.insertAttachment(attachment);
      }
    }

  });
});