define('ember-gestures/components/fast-async', ['exports', 'ember-gestures/templates/components/fast-async', 'ember-gestures/components/async-element'], function (exports, _emberGesturesTemplatesComponentsFastAsync, _emberGesturesComponentsAsyncElement) {
  'use strict';

  exports['default'] = _emberGesturesComponentsAsyncElement['default'].extend({
    layout: _emberGesturesTemplatesComponentsFastAsync['default'],
    tagName: 'button',
    attributeBindings: ['style', 'disabled', 'type'],
    style: Ember.String.htmlSafe('touch-action: manipulation; -ms-touch-action: manipulation;'),
    type: 'button',
    text: '',
    context: null
  });
});