define('ember-intl/utils/links', ['exports'], function (exports) {
  'use strict';

  var PROJECT_URL = 'https://github.com/jasonmit/ember-intl';

  exports['default'] = {
    unsetLocale: PROJECT_URL + '#setting-runtime-locale',
    asyncTranslations: PROJECT_URL + '/blob/master/docs/asynchronously-loading-translations.md#asynchronous-loading-of-translations',
    polyfill: PROJECT_URL + '/blob/master/docs/intljs-polyfill.md'
  };
});